export default [{
        title: 'Inicio',
        route: 'home',
        icon: 'HomeIcon',
        action: 'read',
        resource: 'ACL'
    },
    {
        title: 'Proveedores',
        icon: 'BriefcaseIcon',
        children: [{
            title: 'Lista',
            route: 'providers'
        }]
    },
    {
        title: 'Revisores',
        icon: 'PenToolIcon',
        children: [{
            title: 'Lista',
            route: 'revisers'
        }]
    },
    // {
    //     title: 'Formularios',
    //     icon: 'BriefcaseIcon',
    //     action: 'read',
    //     resource: 'ACL'
    // }
]